import React from "react"
import {graphql} from "gatsby"
import Billboard from '../components/billboard'
import Questions from '../components/questions'
import Team from "../components/team"
import { Row, Col, Container } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
const IndexPage = (props) => {
  const resources = props.data.contentfulResources.edges;
  return (
    <Layout pageInfo={{ pageName: "Hiring Managers" }}>
      <Billboard image={props.data.heroImage.childImageSharp.gatsbyImageData}/>

      <Container className="mb-5">
        <Row>
          <Col md={6}>
            <h2 className="section-title">For Hiring Managers</h2>
            <p>
              As a hiring manager in the MSP program, this site is where you will access all of the information, resources and documents you need in order to be successful and effective.
            </p>
            <p>
              If you feel this site is missing anything, or if you have questions after reviewing its contents, please email us at <a href="mailto:commonwealth@sevensteprpo.com" className="link-no-style">commonwealth@sevensteprpo.com</a>.
            </p>
          </Col>
          <Col md={6} className="resources-list">
            <h2 className="section-title">Document Library </h2>
            <ul>
              {resources.map(({ node }) => {
                return (
                <li key={node.id}>
                  <a href={node.attachment.file.url}>{node.name}</a>
                </li>
                )
              })}
            </ul>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="text-center mt-5">
            <a href="https://vms.vectorvms.com/Login_Entry.aspx">
              <h3 className="section-title">LOG IN TO VECTORVMS</h3>
            </a>
          </Col>
        </Row>
      </Container>
      <div className="training-video p-5 bg-light">
        <Container className="text-center">
          <h2 className="text-uppercase section-title mb-4">TRAINING VIDEO</h2>
          <iframe title="hiring-mngt-video" src="https://player.vimeo.com/video/440103112" height="460" frameBorder="0" allow="autoplay; fullscreen"></iframe>
        </Container>
      </div>
      <Questions />
      <Team />
    </Layout>
  );
}

export default IndexPage

export const Head = () => (
  <Seo title="Hiring Managers" keywords={[`Hiring`, `Managers`, `Resources`]} />
)

export const query = graphql`{
  heroImage: file(relativePath: {eq: "hero.jpg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, placeholder: TRACED_SVG, layout: FULL_WIDTH)
    }
  }
  AboutImage: file(relativePath: {eq: "about.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 500
        quality: 100
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
  contentfulResources: allContentfulResources(
    filter: {category: {eq: "hiring-managers"}}
  ) {
    edges {
      node {
        id
        name
        category
        attachment {
          file {
            url
          }
        }
      }
    }
  }
}
`;
